import React, { useRef, useEffect } from 'react';
import css from './fitlerModal.module.css';
import closeIcon from './assets/closeIcon.svg';
import { Button } from '../../../../components';
import { FormattedMessage } from 'react-intl';

const FilterModal = ({ isOpen, onClose, children, clearFilter, totalItems }) => {
  const modalRef = useRef();

  useEffect(() => {
    if (typeof window === 'undefined') return;
    const handleClickOutside = event => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className={css.modalOverlay}>
      <div className={css.modalContent} ref={modalRef}>
        <div className={css.filterheader}>
          <div>Filter</div>
          <div className={css.closeButton} onClick={onClose}>
            <img src={closeIcon} alt="close icon" />
          </div>
        </div>
        <div className={css.modalBody}>{children}</div>
        <div className={css.modalFooter}>
          <div onClick={clearFilter} className={css.clearButton}>
            <FormattedMessage id="SearchFiltersMobile.resetAll" />
          </div>{' '}
          <div>
            {' '}
            <Button
              className={css.submitButton}
              type="submit"
              onClick={onClose}
              inProgress={false}
              disabled={false}
              ready={false}
            >
              <FormattedMessage
                id="SearchFiltersMobile.foundResults"
                values={{ count:  totalItems  }}
              />
            </Button>{' '}
          </div>{' '}
        </div>
      </div>
    </div>
  );
};

export default FilterModal;
