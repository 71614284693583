import React, { useEffect, useState } from 'react';
import { arrayOf, bool, func, object, string } from 'prop-types';
import { compose } from 'redux';
import classNames from 'classnames';

import { injectIntl, intlShape } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import { formatMoney } from '../../../util/currency';
import { ensureListing } from '../../../util/data';

import { AspectRatioWrapper } from '../../../components';
import arrowRight from '../../../assets/icons/arrows/vect1-orange.svg';
import arrowLeft from '../../../assets/icons/arrows/vect2-orange.svg';
import Spinner from '../loadingSpinner/loadingSpinner';
import css from './SearchMapInfoCard.module.css';

// ListingCard is the listing info without overlayview or carousel controls
const ListingCard = props => {
  const { className, clickHandler, intl, isInCarousel, listing, urlToListing, config } = props;
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [currentListingId, setListingId] = useState('');
  const [lang, setLang] = useState('en');
  const { title, price, publicData } = listing.attributes;
  const { stayTitleDe, stayTitle, experienceTitle, experienceTitleDe, listingType } =
    publicData || {};
  const [imageLoaded, setImageLoaded] = useState(false);
  const isExperienceType = listingType == 'impact-experience';

  useEffect(() => {
    const langMatch = urlToListing.match(/^\/([a-z]{2})\//);
    const lang = langMatch[1];
    setLang(lang);
  }, [urlToListing]);
  const stayTitleMaybe =
    lang === 'en' ? stayTitle : stayTitleDe?.length > 0 ? stayTitleDe : stayTitle;

  const experienceTitleMaybe =
    lang === 'en'
      ? experienceTitle
      : experienceTitleDe?.length > 0
      ? experienceTitleDe
      : experienceTitle;

  //tile
  const titleFirstPart = title.split('•')[0];
  // roomType
  const roomType = listing?.attributes?.publicData?.roomtype;
  //is Experience
  const experienceType = title.split('• ')[1];
  
  const experienceTitleRaw =
    experienceType === '2 Days 1 Night'
      ? intl.formatMessage({ id: 'experienceTitle.2d1n' })
      : experienceType === '3 Days 2 Nights'
      ? intl.formatMessage({ id: 'experienceTitle.3d2n' })
      : experienceType === '4 Days 3 Nights'
      ? intl.formatMessage({ id: 'experienceTitle.4d3n' })
      : experienceType === '5 Days 4 Nights'
      ? intl.formatMessage({ id: 'experienceTitle.5d4n' })
      : experienceType === '6 Days 5 Nights'
      ? intl.formatMessage({ id: 'experienceTitle.6d5n' })
      : experienceType === '7 Days 6 Nights'
      ? intl.formatMessage({ id: 'experienceTitle.1w' })
      : experienceType === '8 Days 7 Nights'
      ? intl.formatMessage({ id: 'experienceTitle.8d7n' })
      : experienceType === '9 Days 8 Nights'
      ? intl.formatMessage({ id: 'experienceTitle.9d8n' })
      : experienceType === '10 Days 9 Nights'
      ? intl.formatMessage({ id: 'experienceTitle.10d9n' })
      : experienceType === '11 Days 10 Nights'
      ? intl.formatMessage({ id: 'experienceTitle.11d10n' })
      : experienceType === '12 Days 11 Nights'
      ? intl.formatMessage({ id: 'experienceTitle.12d11n' })
      : experienceType === '13 Days 12 Nights'
      ? intl.formatMessage({ id: 'experienceTitle.13d12n' })
      : experienceType === '14 Days 13 Nights'
      ? intl.formatMessage({ id: 'experienceTitle.2w' })
      : experienceType === '21 Days 20 Nights'
      ? intl.formatMessage({ id: 'experienceTitle.3w' })
      : null;

  // function to get roomType
  const titleSecondpart =
    roomType === 'singlebedroom'
      ? intl.formatMessage({ id: 'roomtypes.singlebedroom' })
      : roomType === 'doublebedroom'
      ? intl.formatMessage({ id: 'roomtypes.doublebedroom' })
      : roomType === 'twobedroom'
      ? intl.formatMessage({ id: 'roomtypes.twobedroom' })
      : roomType === 'shared_bedroom'
      ? intl.formatMessage({ id: 'roomtypes.shared_bedroom' })
      : roomType === 'multi_bedroom'
      ? intl.formatMessage({ id: 'roomtypes.multi_bedroom' })
      : roomType === 'entire_accomodation'
      ? intl.formatMessage({ id: 'roomtypes.entire_accomodation' })
      : roomType === 'tent'
      ? intl.formatMessage({ id: 'roomtypes.tent' })
      : experienceTitle
      ? experienceTitle
      : 'not found';

  const newTitle = isExperienceType
    ? experienceTitleMaybe
      ? experienceTitleMaybe
      : experienceTitleRaw
    : stayTitleMaybe
    ? stayTitleMaybe
    : titleFirstPart + ' • ' + titleSecondpart;

  const formattedPrice =
    price && price.currency === config.currency ? formatMoney(intl, price) : price.currency;
  const listingImages = listing.images && listing.images.length > 0 ? listing.images : null;

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const variants = listingImages
    ? Object.keys(listingImages[0]?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  // listing card anchor needs sometimes inherited border radius.
  const classes = classNames(
    css.anchor,
    css.borderRadiusInheritTop,
    { [css.borderRadiusInheritBottom]: !isInCarousel },
    className
  );

  const formatPrice = formattedPrice => {
    let numericPrice = formattedPrice.replace(/[^0-9,\.]/g, '');

    if (numericPrice.indexOf(',') > numericPrice.indexOf('.')) {
      numericPrice = numericPrice.replace(',', '.');
    } else {
      numericPrice = numericPrice.replace(',', '');
    }

    let roundedPrice = Math.ceil(parseFloat(numericPrice));

    if (isNaN(roundedPrice)) {
      console.error('Invalid price format:', formattedPrice);
      return 'N/A';
    }

    return `${roundedPrice}€`;
  };
  let finalPrice = formatPrice(formattedPrice);

  const handlePrevImage = e => {
    e.preventDefault();
    e.stopPropagation();
    setCurrentImageIndex(
      prevIndex => (prevIndex - 1 + listingImages.length) % listingImages.length
    );
  };

  const handleNextImage = e => {
    e.preventDefault();
    e.stopPropagation();
    setCurrentImageIndex(prevIndex => (prevIndex + 1) % listingImages.length);
  };

  useEffect(() => {
    if (listing?.id?.uuid !== currentListingId) {
      setListingId(listing?.id?.uuid);
      setCurrentImageIndex(0);
    }
  }, [listing]);

  return (
    <a
      alt={title}
      className={classes}
      href={urlToListing}
      onClick={e => {
        e.preventDefault();
        // Use clickHandler from props to call internal router
        clickHandler(listing);
      }}
      data-elb="searchp"
      data-elbaction={`click:click map-${listing?.attributes?.title}-uuid-${listing?.id?.uuid}`}
    >
      <div
        className={classNames(css.card, css.borderRadiusInheritTop, {
          [css.borderRadiusInheritBottom]: !isInCarousel,
        })}
      >
        <AspectRatioWrapper
          className={css.aspectRatioWrapper}
          width={aspectWidth}
          height={aspectHeight}
        >
          {!imageLoaded && <Spinner />}
          <img
            src={listingImages[currentImageIndex]?.attributes?.variants['listing-card']?.url}
            className={classNames(css.rootForImage, { [css.imageLoaded]: imageLoaded })}
            alt={title}
            onLoad={() => setImageLoaded(true)}
            style={{ display: imageLoaded ? 'block' : 'none' }}
          />
          {listingImages && listingImages.length > 1 && (
            <>
              <button
                onClick={handlePrevImage}
                className={classNames(css.arrowButton, css.arrowLeft)}
              >
                <img src={arrowLeft} className={css.arrowImg} alt="arrowLeft" />
              </button>
              <button
                onClick={handleNextImage}
                className={classNames(css.arrowButton, css.arrowRight)}
              >
                <img src={arrowRight} className={css.arrowImg} alt="arrowRight" />
              </button>
            </>
          )}
        </AspectRatioWrapper>
        <div className={classNames(css.info, { [css.borderRadiusInheritBottom]: !isInCarousel })}>
          <div className={css.price}>{finalPrice}</div>
          <div className={css.name}>{newTitle}</div>
        </div>
      </div>
    </a>
  );
};

ListingCard.defaultProps = {
  className: null,
};

ListingCard.propTypes = {
  className: string,
  listing: propTypes.listing.isRequired,
  clickHandler: func.isRequired,
  intl: intlShape.isRequired,
  isInCarousel: bool.isRequired,
};

const SearchMapInfoCard = props => {
  const [currentListingIndex, setCurrentListingIndex] = useState(0);
  const {
    className,
    rootClassName,
    intl,
    listings,
    createURLToListing,
    onListingInfoCardClicked,
    config,
  } = props;
  const currentListing = ensureListing(listings[currentListingIndex]);
  const hasCarousel = listings.length > 1;

  const classes = classNames(rootClassName || css.root, className);
  const caretClass = classNames(css.caret, { [css.caretWithCarousel]: hasCarousel });

  return (
    <div className={classes}>
      <div className={css.caretShadow} />
      <ListingCard
        clickHandler={onListingInfoCardClicked}
        urlToListing={createURLToListing(currentListing)}
        listing={currentListing}
        intl={intl}
        isInCarousel={hasCarousel}
        config={config}
      />
      {hasCarousel ? (
        <div className={classNames(css.paginationInfo, css.borderRadiusInheritBottom)}>
          <button
            className={css.paginationPrev}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              setCurrentListingIndex(
                prevListingIndex => (prevListingIndex + listings.length - 1) % listings.length
              );
            }}
          />
          <div className={css.paginationPage}>
            {`${currentListingIndex + 1}/${listings.length}`}
          </div>
          <button
            className={css.paginationNext}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              setCurrentListingIndex(
                prevListingIndex => (prevListingIndex + listings.length + 1) % listings.length
              );
            }}
          />
        </div>
      ) : null}
      <div className={caretClass} />
    </div>
  );
};

SearchMapInfoCard.defaultProps = {
  className: null,
  rootClassName: null,
};

SearchMapInfoCard.propTypes = {
  className: string,
  rootClassName: string,
  listings: arrayOf(propTypes.listing).isRequired,
  onListingInfoCardClicked: func.isRequired,
  createURLToListing: func.isRequired,
  config: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(injectIntl)(SearchMapInfoCard);
